﻿import type {AccessToken, CompressedScopeItem, ScopeSources, ScopeItem, ScopeID} from "@tnt/mate-abstractions";

import {decompressScopeItems} from "./decompress";
import {ref} from "vue";

interface ScopeLoader {
    load(accessToken: AccessToken): Promise<CompressedScopeItem[]>;
}

export const scopeSources = (scopeLoader?: ScopeLoader): ScopeSources => {

    const scopesLoaded = ref(false);
    const scopesLoading = ref(false);
    const compressedScopes = ref<CompressedScopeItem[]>([]);

    const globals = ref<ScopeItem[]>([]);
    const countries = ref<ScopeItem[]>([]);
    const tenants = ref<ScopeItem[]>([]);
    const locations = ref<ScopeItem[]>([]);
    const terminals = ref<ScopeItem[]>([]);

    const updateScopeSources = async (accessToken: AccessToken) => {
        scopesLoading.value = true;
        try {
            if (scopeLoader) {
                compressedScopes.value = await scopeLoader.load(accessToken);
            }
            globals.value = decompressScopeItems(compressedScopes.value, [], "GLOBAL");
            countries.value = decompressScopeItems(compressedScopes.value, globals.value, "COUNTRY");
            tenants.value = decompressScopeItems(compressedScopes.value, countries.value, "TENANT");
            locations.value = decompressScopeItems(compressedScopes.value, tenants.value, "LOCATION");
            terminals.value = decompressScopeItems(compressedScopes.value, locations.value, "TERMINAL");
            scopesLoaded.value = true;
        } finally {
            scopesLoading.value = false;
        }
    };

    const getScopeItems = (id: ScopeID): ScopeItem[] => {
        switch (id) {
            case "GLOBAL":
                return globals.value;
            case "COUNTRY":
                return countries.value;
            case "TENANT":
                return tenants.value;
            case "LOCATION":
                return locations.value;
            case "TERMINAL":
                return terminals.value;
        }
    };

    const getScopeItem = (id: ScopeID, index: number): ScopeItem => {
        const items = getScopeItems(id);
        return items.filter(i => i.index === index)[0];
    };

    const getByOid = (items: ScopeItem[], oid?: number): ScopeItem =>
        oid ? items.filter(scopeItem => scopeItem.oid == oid)[0] : <any>undefined;

    const getTenant = (oid?: number): ScopeItem => getByOid(tenants.value, oid);
    const getLocation = (oid?: number): ScopeItem => getByOid(locations.value, oid);
    const getTerminal = (oid?: number): ScopeItem => getByOid(terminals.value, oid);

    const getLocations = (tenantOid: number): ScopeItem[] => {
        const list = locations.value.filter(scopeItem => scopeItem.parent?.oid == tenantOid);
        if (list.length > 0) {
            const numberList = list
                .map(i => ({id: parseInt(i.id as string), i}))
                .filter(i => i.id || i.id === 0);
            return numberList.length === list.length
                ? numberList.sort((a, b) => a.id - b.id).map(i => i.i)
                : list.sort((a, b) => (a.id || '').localeCompare(b.id || ''));
        }
        return list;
    }

    const getTerminals = (locationRoleOid?: number): ScopeItem[] =>
        terminals.value.filter(scopeItem => scopeItem.parent?.oid == locationRoleOid);

    const createTenantIdDict = () =>
        tenants.value.reduce((dict, t) => {
            dict[<string>t.id] = <number>t.oid;
            return dict;
        }, <Record<string, number>>{})

    const createTenantOidDict = () =>
        tenants.value.reduce((dict, t) => {
            dict[<number>t.oid] = <string>t.id;
            return dict;
        }, <Record<number, string>>{})

    return {
        scopesLoaded,
        scopesLoading,

        globals,
        countries,
        tenants,
        locations,
        terminals,

        updateScopeSources,
        getScopeItems,
        getScopeItem,
        getTenant,
        getLocation,
        getTerminal,
        getLocations,
        getTerminals,

        createTenantIdDict,
        createTenantOidDict
    }
};
