﻿import type {AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError, AxiosBasicCredentials} from "axios";

import {useMateApi} from "./useMateApi";

//Todo: recaptcha fields

export interface LoginRequest extends AxiosBasicCredentials {
    //username?: string;
    //password?: string;
    // captcha?: string;
}

export interface AccessTokenResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
}

export interface LoginResponse {
    username: string;
    token: string;
}

export interface RegisterRequest {
    username?: string;
    password?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    // captcha?: string;
}

export interface ResetPasswordRequest {
    userId?: string;
    token?: string;
    newPassword?: string;
}

export interface PrepareResetPasswordRequest {
    email?: string;
}

export interface ValidateEmailRequest {
    userId?: string;
    token?: string;
}

export interface RegisterResponse {
}


export const useAuthApi = () => {

    const {jsonEndpoint} = useMateApi("ANONYMOUS")
    const authApi = jsonEndpoint("/api/v1-pub/auth/user");
    const accountApi = jsonEndpoint("/api/auth");

    const params: any = undefined;

    const login = (loginRequest: LoginRequest) => authApi
        .get<AccessTokenResponse>(undefined, {
            url: "/token.json",
            auth: loginRequest,
        })
        .then(response => {
            return <LoginResponse>{
                username: loginRequest.username,
                token: response.access_token
            }
        });

    const register = (request: RegisterRequest) => accountApi.post<RegisterResponse>(request, params, {url: "/register"});
    const resetPassword = (request: PrepareResetPasswordRequest) => accountApi.post(request, params, {url: "/reset-password"});
    const resetPasswordSubmit = (request: ResetPasswordRequest) => accountApi.post<LoginResponse>(request, params, {url: "/set-password"});
    const validateEmail = (request: ValidateEmailRequest) => accountApi.post<LoginResponse>(request, params, {url: "/confirm-registration"});

    return {
        login,
        register,
        resetPassword,
        resetPasswordSubmit,
        validateEmail
    }
}