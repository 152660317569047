﻿<template>

    <aunoa-nav-item
        v-if="user"
        id="userNavItem"
        :drop-direction="dropDirection"
        :drop-menu-class="dropMenuClass"
        :header-text="headerText"
    >
        <template v-slot:link>
            <aunoa-gravatar
                class="rounded-circle gravatar"
                :default-img="defaultGravatarImage"
                :email-address="user.emailAddress"
                :size="22"
            />
            <i class="fad fa-user" v-if="false" />
            <span class="ms-2 d-none d-md-inline" v-text="user.username" />
        </template>

        <li>
            <div class="px-3 pt-3 pb-1 text-center">
                <aunoa-gravatar
                    class="rounded-circle gravatar d-inline-block mb-2"
                    :default-img="defaultGravatarImage"
                    :email-address="user.emailAddress"
                    :size="80" />
                <span class="d-block" v-text="user.username" />
                <span class="d-block small text-muted" v-text="user.emailAddress" />
            </div>
        </li>
        <aunoa-dropdown-divider v-if="$slots.default" />
        <slot />

        <template v-if="auth.changePasswordRoute">
            <aunoa-dropdown-divider />
            <li>
                <router-link class="dropdown-item" :to="auth.changePasswordRoute">
                    <i class="fa-fw far me-3" />
                    <span v-text="t('Aunoa.Auth.ChangePasswordCommand')" />
                </router-link>
            </li>
        </template>
        <template v-if="auth.logoutRoute">
            <aunoa-dropdown-divider />
            <aunoa-dropdown-item
                icon="fas"
                :text="t('Aunoa.Auth.Lock')"
                @click="$emit('lock')"
            />
            <li>
                <router-link class="dropdown-item" :to="auth.logoutRoute">
                    <i class="fa-fw me-3 fad fa-sign-out-alt" />
                    <span v-text="t('Aunoa.Auth.LogoutCommand')" />
                </router-link>
            </li>

        </template>
    </aunoa-nav-item>

</template>

<script lang="ts">

import type {PropType} from "vue";
import type {AunoaOptions} from "..";
import type {DefaultGravatarImage} from "./AunoaGravatar.vue";

import AunoaDropdownCheckItem from "./dropdown/AunoaDropdownCheckItem.vue";
import AunoaSelectNavItemMixin from "../mixins/AunoaSelectNavItemMixin";
import AunoaDropdownDivider from "./dropdown/AunoaDropdownDivider.vue";
import AunoaDropdownItem from "./dropdown/AunoaDropdownItem.vue";
import {useAunoaOptions} from "../utils/useAunoaOptions";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import AunoaNavItem from "./nav/AunoaNavItem.vue";
import AunoaGravatar from "./AunoaGravatar.vue";
import UserMixin from "../mixins/UserMixin";
import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaUserNavItem",
    mixins: [AunoaSelectNavItemMixin, UserMixin],
    components: {
        AunoaDropdownCheckItem,
        AunoaDropdownDivider,
        AunoaDropdownItem,
        AunoaGravatar,
        AunoaNavItem
    },
    props: {
        defaultGravatarImage: {
            type: String as PropType<DefaultGravatarImage>,
            default: "blank"
        }
    },
    emits: ["lock"],
    setup() {
        const {t} = useAunoaI18n();
        const {options} = useAunoaOptions();

        return {
            t,
            ...<AunoaOptions>options
        }
    }
});

</script>

