
import type {App, Ref} from "vue";
import {provideMateBaseUrl} from "./base/useMateBaseUrl";

export type {AccessToken, Tnt} from "./types";
export type {LoginRequest, LoginResponse, RegisterRequest, RegisterResponse, ValidateEmailRequest, PrepareResetPasswordRequest,  ResetPasswordRequest} from "./useAuthApi";
export type {RecentChange, RecentChanges} from "./useConfigApi";
export type {ExchangeRate} from "./useCurrenciesApi";
export type {Datasource} from "./useDocumentDatasources";
export type {TableInfoSummary, TableInfo, MateVersionInfo} from "./useInfoApi";
export type {DocumentHeader, ExampleDocumentHeader} from "./useReceiptApi";
export type {UserProfile, CardStorage, DashboardStorage, ChangePasswordRequest, ChangePasswordResponse} from "./useUserApi";
export type {PosClassicPackagesVersionInfo} from "./usePosClassicInfoApi";
export type {UnprocessedDocument} from "./useDiagnosticsApi";
export type {Bridge, JobItem} from "./hub/createBridgeFacade";
export type {HtmlDocumentPreview} from "./useDocumentToolsApi";
export type {ShoppingCartItemDto, ShoppingCartDocumentHeader} from "./useShoppingCartApi";

export {createConnectingBridge} from "./hub/createBridgeFacade";
export {useMateHub, provideMateHub} from "./hub/useMateHub";

export {useAxiosActivities, interceptAxiosActivities} from "./base/useAxiosActivities";
export {useAccessToken, provideAccessToken} from "./useAccessToken";
export {useAuthApi} from "./useAuthApi";
export {useChatApi} from "./useChatApi";
export {useConfigApi} from "./useConfigApi";
export {useCurrenciesApi} from "./useCurrenciesApi";
export {useDataApi, DataGroup, DataType} from "./useDataApi";
export {useDiagnosticsApi} from "./useDiagnosticsApi";
export {useDocumentApi, DocumentGroup, DocumentType} from "./useDocumentApi";
export {useDocumentDatasources} from "./useDocumentDatasources";
export {useMateApi} from "./useMateApi";
export {useDocumentToolsApi} from "./useDocumentToolsApi";
export {useInfoApi} from "./useInfoApi";
export {useInventoryApi} from "./useInventoryApi";
export {usePosClassicInfoApi} from "./usePosClassicInfoApi";
export {usePosInstallerApi} from "./usePosInstallerApi";

export {useReceiptApi, convertToDocumentHeader} from "./useReceiptApi";
export {useReportApi, ReportGroup, ReportType} from "./useReportApi";
export {useScope} from "./useScope";
export {useScopeApi} from "./useScopeApi";
export {useScopeNavigation} from "./useScopeNavigation";
export {useScopeSources, provideScopeSources} from "./useScopeSources";
export {useShoppingCartApi} from "./useShoppingCartApi";

export {useTenancy, useCurrentTenant, useTenancyFromProps, useCachedTenancyDataFromProps, setupPrimaryTenancy, usePrimaryTenancyEntity} from "./useTenancy";
export {useTurnoverApi} from "./useTurnoverApi";
export {useNavigationApi} from "./useNavigationApi";
export {usePingApi} from "./usePingApi";
export {useUserApi} from "./useUserApi";
export {provideUserRelogin} from "./useUserRelogin";

export {useDownloadHelper, getAcceptHeader} from "./useDownloadHelper";

export interface MateApiOptions {
    baseUrl?: string;
    locale: Ref<string>;
    //primaryTenantOid: Ref<number>;
}

import {provideLocale} from "./useLocale";
import {usePosInstallerApi} from "./usePosInstallerApi";

const install = (app: App, options: MateApiOptions) => {
    if (!options) {
        throw Error("MATE installation requires options");
    }
    if (!options.locale) {
        throw Error("MATE installation requires local Ref");
    }
    provideLocale(options.locale);
    provideMateBaseUrl(options.baseUrl || '/' );
};

export default {
    install
}