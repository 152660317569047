﻿import {AccessToken} from "./types";
import {useMateApi} from "./useMateApi";
import {setTenancy} from "./useTenancy";

export interface UserProfile {
    id: string;
    username: string;
    emailAddress?: string/* | null*/;
    firstName?: string;
    lastName?: string;
    rootTenantOid: number;
    primaryTenantOid: number;
    tenantOids: number[];
}

export interface CardStorage {
    name: string;
    params: Record<string, any>
}

export interface DashboardStorage {
    version: number;
    storedAt: Date;
    cards: Record<string, CardStorage>;
}

export interface ChangePasswordRequest {
    password?: string;
    newPassword?: string;
}

export interface ChangePasswordResponse {
}


export const useUserApi = (accessToken: AccessToken) => {

    const {jsonEndpoint} = useMateApi(accessToken)
    const userApi = jsonEndpoint("/api/user");
    const authApi = jsonEndpoint("/api/auth");

    const getUserProfile = () => userApi.get<UserProfile>(undefined, {url: "/profile"})
        .then(userProfile => {
            setTenancy(userProfile);
            return userProfile;
        });

    const changePassword = (request:ChangePasswordRequest) => authApi.post<ChangePasswordResponse>(request, undefined, {url: "/change-password"});

    const useDashboardStore = () => {
        const options = {url: "/extension/dashboard"};

        const get = () => userApi.get<DashboardStorage>(undefined, options);
        const set = (dashboard: DashboardStorage) => userApi.put(dashboard, undefined, options);

        return {
            get,
            set
        }
    }

    return {
        changePassword,
        getUserProfile,
        useDashboardStore
    }
}
