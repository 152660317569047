import type {Ref, InjectionKey} from "vue";
import type {ActionFacade} from "./createActionFacade";
import type {BridgeFacade} from "./createBridgeFacade";
import type {MonitoringFacade} from "./createMonitoringFacade";
import type {DataServiceFacade} from "./createDataServiceFacade";
import type {NotificationFacade} from "./createNotificationFacade";

import {HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {ref, readonly, inject, provide, onMounted, onBeforeUnmount} from "vue";
import {createNotificationFacade} from "./createNotificationFacade";
import {createDataServiceFacade} from "./createDataServiceFacade";
import {createMonitoringFacade} from "./createMonitoringFacade";
import {createActionFacade} from "./createActionFacade";
import {createBridgeFacade} from "./createBridgeFacade";
import {useMateBaseUrl} from "../base/useMateBaseUrl";
import {useInfoApi} from "../useInfoApi";

declare type State = "Disconnected" | "Connecting" | "Connected" | "Disconnecting" | "Reconnecting";

interface MateHub {
    error: Ref<Error | undefined>;
    connectionId: Ref<string | null>;
    state: Ref<State | undefined>;
    hasUpdate: Ref<boolean>;

    bridge: BridgeFacade;
    actions: ActionFacade;
    monitoring: MonitoringFacade;
    dataService: DataServiceFacade,
    notification: NotificationFacade;
}

const INJECTION_KEY: InjectionKey<MateHub> = Symbol("TNT_MATE_HUB");

export const provideMateHub = (accessToken: Ref<string | undefined>) => {

    const _error = ref<any>();
    const _connectionId = ref<string | null>(null);
    const _state = ref<State>(<State>HubConnectionState.Disconnected);

    const {baseUrl} = useMateBaseUrl();

    const {getApiVersionInfo} = useInfoApi(accessToken)

    const hasUpdate = ref(false);
    const apiBuildDate = ref<Date>();

    const connection = new HubConnectionBuilder()
        .withUrl(baseUrl + "api/hub/mate", {accessTokenFactory: () => <string>accessToken.value})
        .configureLogging(LogLevel.Information)
        //.configureLogging(LogLevel.Debug)
        //.withAutomaticReconnect([5000])
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds(retryContext) {
                //console.log(retryContext);
                return 5000;
            }
        })
        .build();

    const facades = {
        bridge: createBridgeFacade(connection),
        actions: createActionFacade(connection),
        monitoring: createMonitoringFacade(connection),
        dataService: createDataServiceFacade(connection),
        notification: createNotificationFacade(connection),
    }

    const initialize = () => Promise.all(Object.entries(facades).map(([_, proxy]) => proxy.initialize()));

    const start = async () => {
        _error.value = undefined;
        _state.value = connection.state;
        try {
            await connection.start();
            await initialize();
        } catch (err) {
            _error.value = err;
            setTimeout(start, 5000);
        } finally {
            _state.value = connection.state;
            _connectionId.value = connection.connectionId;
        }
    }

    const onClose = (error?: Error) => {
        _error.value = error;
        _state.value = connection.state;
        _connectionId.value = connection.connectionId;
    };

    const onReconnecting = (error?: Error) => {
        _error.value = error;
        _state.value = connection.state;
        _connectionId.value = connection.connectionId;
    };

    const onReconnected = (connectionId?: string) => {
        _error.value = undefined;
        _state.value = connection.state;
        _connectionId.value = connectionId || null;
        getApiVersionInfo().then(version => {
            if (version.buildDate !== apiBuildDate.value) {
                hasUpdate.value = true;
                apiBuildDate.value = version.buildDate;
            }
        });
    };

    connection.onclose(onClose);
    connection.onreconnecting(onReconnecting);
    connection.onreconnected(onReconnected);

    onMounted(() => {
        start().then(() => {
            getApiVersionInfo().then(version => apiBuildDate.value = version.buildDate);
        });
    });

    onBeforeUnmount(() => {
        Object.entries(facades).forEach(([_, proxy]) => proxy.dispose())
        connection.stop();
    });

    const mateHub: MateHub = {
        error: readonly(_error),
        connectionId: readonly(_connectionId),
        state: readonly(_state),
        hasUpdate: readonly(hasUpdate),
        ...facades
    }
    provide(INJECTION_KEY, mateHub)
    return mateHub;
}


export const useMateHub = () => <MateHub>inject(INJECTION_KEY);
