﻿import type {RouteLocationNormalized, RouteLocationRaw} from "vue-router";

import {reactive, ref} from "vue";

export interface LoginRequest {
    username: string | undefined;
    password: string | undefined;
    remember?: boolean | undefined;
}

export interface AunoaOptions {
    auth: {
        loginRoute?: Partial<RouteLocationNormalized>;
        logoutRoute?: Partial<RouteLocationNormalized>;
        registerRoute?: Partial<RouteLocationNormalized>;
        resetPasswordRoute?: Partial<RouteLocationNormalized>;
        changePasswordRoute?: Partial<RouteLocationNormalized>;
        reloginPromise(loginRequest: LoginRequest): Promise<any>;
    };
}

const options = reactive<AunoaOptions>({
    auth: {
        loginRoute: undefined,
        logoutRoute: undefined,
        registerRoute: undefined,
        resetPasswordRoute: undefined,
        changePasswordRoute: undefined,
        reloginPromise: (loginRequest: LoginRequest) => Promise.reject("Missing Relogin Promise in Aunoa Options"),
    },
})


export const useAunoaOptions = () => {
    return {
        options
    }
};
