﻿<template>
    <aunoa-card
        tag="router-link"
        v-bind="cardAttrs"
        to="/diagnostics/unprocessed-documents"
        _style="pointer-events: none;"
    >
        <div class="card-body text-center">
            <div class="card-text" v-text="title(t)" />
            <h1 class="m-3" v-text="count" />

        </div>
    </aunoa-card>
</template>

<script lang="ts">

import {useDocumentToolsApi} from "@tnt/mate-api";
import {useDashboardCard} from "../useDashboard";
import {useAunoaI18n, AunoaCard} from "bootstrap-aunoa";
import {useAuth} from "../../../utils/useAuth";
import {defineComponent, ref} from "vue";

const count = ref<number>(0);

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-file-times",
    title: (t: any) => t("Sidebar.UnprocessedDocuments")
}

export default defineComponent({
    $$meta: meta,
    name: "UnprocessedDocumentsCard",
    components: {AunoaCard},
    setup() {

        const {t} = useAunoaI18n();
        const {accessToken} = useAuth();
        const {getUnprocessedDocuments} = useDocumentToolsApi(accessToken);

        const card = useDashboardCard({
            update: () =>
                getUnprocessedDocuments().then(result => {
                    count.value = Object.entries(result)
                        //.map(([tenantOid, count]) => ({tenantOid: Number(tenantOid), count: count}))
                        //.reduce((total, r) => total count)
                        .map(([tenantOid, count]) => count >= 0 ? count : 0)
                        .reduce((total, count) => total + count, 0);
                    card.isDanger.value = count.value > 0;
                })
        });

        return {
            t,
            count,
            ...meta,
            ...card
        }
    }
});

</script>