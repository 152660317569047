﻿import type {AxiosError, AxiosResponse} from "axios";

export interface ApiError {
    message: string;
    errorCode?: number;
    subject?: string;
    icon?: string;
    httpMethod?: string;
    httpStatus?: number;
    httpStatusText?: string;
    stackFrames?: any;
}

const statusCodeResolver = (statusCode: number) => {

    const icon = () => {
        switch (statusCode) {
            case 403:
                return "fad fa-ban";
            default:
                return undefined;
        }
    }

    const subject = () => {
        switch (statusCode) {
            case 401:
            case 403:
                return "@@Aunoa.Auth.Authorization";
            default:
                return undefined;
        }
    }

    const message = () => {
        switch (statusCode) {
            case 403:
                return "@@Aunoa.Auth.Error.Forbidden";
            default:
                return undefined;
        }
    }

    return {
        icon,
        subject,
        message
    }
}

const errorCodeResolver = (errorCode: number) => {

    const icon = () => {
        switch (errorCode) {
            case 40103:
            case 40104:
                return "fad fa-user-lock";
            default:
                return undefined;
        }
    }

    const subject = () => {
        if (errorCode >= 40100 && errorCode <= 40199) {
            return "@@Aunoa.Auth.Authorization";
        }
        return undefined;
    }

    const message = () => {
        switch (errorCode) {
            case 40101:
            case 40102:
                return "@@Aunoa.Auth.Error.InvalidUsernamePassword";
            case 40103:
                return "@@Aunoa.Auth.Error.LockedOut";
            case 40104:
                return "@@Aunoa.Auth.Error.Disabled";
            default:
                return undefined;
        }
    }

    return {
        errorCode,
        icon,
        subject,
        message
    }
}

const codeResolver = (response?: AxiosResponse, errorCode?: number) => {
    const s = statusCodeResolver(response?.status || -1);
    const e = errorCodeResolver(errorCode || -1);

    const icon = () => e.icon() || s.icon();
    const subject = () => e.subject() || s.subject();
    const message = () => e.message() || s.message();

    return {
        errorCode: e.errorCode,
        icon : icon(),
        subject: subject(),
        message: message()
    }

}


export const toApiError = (axiosError: AxiosError) => {

    if (axiosError.response?.statusText) {
        const match = /\[(\w{5})]/g.exec(axiosError.response.statusText);
        if (match) {
            const resolver = codeResolver(axiosError.response, parseInt(match[1]));
            return <ApiError>{
                message: resolver.message || axiosError.response.statusText.substring(0, match.index).trim(),
                errorCode: resolver.message ? undefined : resolver.errorCode,
                subject: resolver.subject,
                icon: resolver.icon,
                httpMethod: axiosError.config?.method,
                httpStatus: axiosError.response?.status || 0,
                httpStatusText: axiosError.response?.statusText,
                stackFrames: undefined,
            };
        }
    }

    let error = axiosError.response?.data || axiosError;

    if (error.error_description && error.error_code) {
        const resolver = codeResolver(axiosError.response, error.error_code);
        return <ApiError>{
            message: resolver.message || error.error_description,
            errorCode: resolver.message ? undefined : resolver.errorCode,
            subject: resolver.subject,
            icon: resolver.icon,
            httpMethod: axiosError.config?.method,
            httpStatus: axiosError.response?.status || 0,
            httpStatusText: axiosError.response?.statusText,
            stackFrames: error.stackFrames,
        };
    }

    error = error["tnt.exception"] || error;

    let message = error.message || error.Message || error;
    message = message.value || message.Value || message;

    if (axiosError.response?.statusText) {
        message = `[${axiosError.response.status}] ${axiosError.response.statusText}:\n${message}`;
    }

    if (error.InnerException?.Message) {
        message += "\n" + error.InnerException?.Message;
    }

    return <ApiError>{
        httpMethod: axiosError.config?.method,
        httpStatus: axiosError.response?.status || 0,
        httpStatusText: axiosError.response?.statusText,
        stackFrames: error.stackFrames,
        message
    };
}

const isBlob = (value: any): value is Blob =>
    value?.size > 0 && value.type && value.text

export const checkForApiError = async (response: AxiosResponse): Promise<ApiError | undefined> => {
    if (isBlob(response.data)) {
        const content = await response.data.text();
        if (typeof content === "string" && content.startsWith("<Fault")) {
            const xml = new DOMParser().parseFromString(content, "application/xml");
            const reason = xml?.documentElement?.getElementsByTagName("Reason")[0]?.getElementsByTagName("Text")[0]?.textContent;
            if (reason) {
                return {
                    message: reason
                }
            }
        }
    }

    return undefined;
}