import { reactive } from "vue";
//, readonly
import { DbArticle, ArticleData } from "./types"

export interface GlobalVars {
  articleData:ArticleData,
  dbItemDefinition:DbArticle[],
  mapping:(string | undefined)[],
  gridColumns:string[] ,
  importEntity:any
}

const articleData = reactive({
    header:[],
    row:[]
})

const importEntity:any = {}

const mapping:string[] = []

const gridColumns:string[] = []

const dbItemDefinition:DbArticle[] = [
  {label: 'ArticleNumber', description:'Main article number, 32 Characters', dbColumn: '', show:true},
  {label: 'GTIN', description:'Barcode/Gtin, 32 Characters', dbColumn: '', show:true, required:true},
  {label: 'InternalNumber', description: 'Article number, 32 Characters, Mandatory', dbColumn: '', show:true},
  {label: 'Text', description: 'Text, 40 Characters, Mandatory', dbColumn: '', show:true},
  {label: 'LongText', description: 'Long text, 166 Characters', dbColumn: '', show:false},
  {label: 'Description', description: 'Description, 1024 Characters', dbColumn: '', show:false},
  {label: 'ProductGroup', description: 'Product Group, 40 Characters', dbColumn: '', show:false},
  {label: 'ProductLine', description: 'Product Line, 40 Characters', dbColumn: '', show:false},
  {label: 'Division', description: 'Division, 40 Characters', dbColumn: '', show:false},
  {label: 'Color', description: 'Colour, 40 Characters', dbColumn: '', show:true},
  {label: 'Size', description: 'Size, 40 Characters', dbColumn: '', show:true},
  {label: 'SizeSortingIndex', description: 'Size-Sorting index', dbColumn: '', show:false},
  {label: 'Material', description: 'Material, 40 Characters', dbColumn: '', show:false},
  {label: 'ImageName', description: 'Image file name (.jpg|.png), 32 Characters', dbColumn: '', show:false},
  {label: 'Tax', description: 'Vat rate, Decimal number', dbColumn: '', show:false},
  {label: 'RetailPrice', description: 'Price, Decimal number', dbColumn: '', show:false},
  {label: 'SuggestedRetailPrice', description: 'Suggested retail price, Decimal number', dbColumn: '', show:false},
  {label: 'DisallowDiscount', description: 'Discount disallowed, Yes/No', dbColumn: '', show:false},
  {label: 'AccountNumber', description: 'Account number, 40 Characters', dbColumn: '', show:false}
]

export default { 
  //state: readonly(state),
  articleData, 
  dbItemDefinition, 
  mapping, 
  gridColumns  
};