import {singleton, useLocalStorage, useSessionStorage} from "bootstrap-aunoa";
import {computed, readonly} from "vue";

export const useAccessTokenStorage = () => singleton("TNT_MATE_ACCESS_TOKEN", () => {

    const _remember = useLocalStorage().items("auth").subscribeBoolean("remember", false);
    const _localToken = useLocalStorage().items("auth").subscribeString("token");
    const _sessionToken = useSessionStorage().items("auth").subscribeString("token");
    const accessToken = computed<string | undefined>(() => <any>(_localToken.value || _sessionToken.value));

    const clearAccessToken = () => {
        _sessionToken.value = undefined;
        _localToken.value = undefined;
    }

    const storeAccessToken = (token: string, remember: boolean | null | undefined) => {
        if (typeof remember !== "boolean") {
            remember = _remember.value;
        }
        if (remember) {
            _remember.value = true;
            _localToken.value = token;
            _sessionToken.value = undefined;
        } else {
            _remember.value = false;
            _sessionToken.value = token;
            _localToken.value = undefined;
        }
    }

    return {
        accessToken,
        remember: readonly(_remember),
        clearAccessToken,
        storeAccessToken,
    }
});